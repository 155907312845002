/* ReferenceImages.css */

/* Parent Container */
.reference-images-container {
    width: 100%;
    padding: 20px;
    background-color: white;
  }
  
  /* Loading Spinner */
  .reference-images-container .spin-container {
    display: flex;
    justify-content: left;
    align-items: left;
    height: 100vh;
  }
  
  /* Subcategory Buttons */
  .reference-images-container .subcategory-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  /* Subcategory Images Wrapper */
  .reference-images-container .subcategory-images {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: left;
  }
  
  /* Individual Subcategory Item */
  .reference-images-container .subcategory-item {
    width: 220px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: left;
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .reference-images-container .subcategory-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Image Styling */
  .reference-images-container .subcategory-item img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
  }
  
  /* Event Type & Created Date Sections */
  .reference-images-container .event-type {
    margin-top: 8px;
    /* font-size: 14px; */
    /* font-weight: bold; */
    background: #e3f2fd;
    padding: 5px;
    border-radius: 5px;
    white-space: pre-line;
  }
  
  .event-type-label {
    color: #000; /* Black or any color for the label */
    font-size: 16px;
    font-weight: bold;
  }
  
  .event-options {
    color: #777; /* Blue or any color for the options */
    font-size: 13px;
    font-weight: 600;
  }
  
  .reference-images-container .created-date {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
    background: #fff3e0;
    padding: 5px;
    border-radius: 5px;
  }
  
  /* Accessories Text */
  .reference-images-container .AccessoriesText-width {
    font-size: 16px;
    text-align: center;
    color: #777;
    margin-top: 20px;
  }
  