/* Overlay for blur effect */
.customer-table-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
  backdrop-filter: blur(8px); /* Blur effect */
  z-index: 10; /* Ensure it's above the rest of the UI */
}

/* Customer Table */
.customer-table {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1200px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 11; /* Ensure it's above the overlay */
  max-height: 90vh; /* Prevents overflow on smaller screens */
  overflow-y: auto; 
  /* overflow: auto; */
}

.table-container {
  /* max-height: 400px;  */
  overflow-y: auto; /* Scroll only for the table */
  margin-top: 10px;
  border: 1px solid #ddd;
}

.customer-table table {
  width: 100%;
  border-collapse: collapse;
}

.customer-table th, .customer-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.customer-table th {
  background-color: #f4f4f4;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #31363f;
  background-color: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
}

.close-button:hover {
  color: #000;
  background-color: #f0f0f0;
}


.download-button {
  background-color: #f0f0f0;
  color: #31363f;
  border: none;
  padding: 10px 15px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
}

.download-button:hover {
  background-color: #31363f;
  color: white;
}

/* Dropdown for selecting download format */
.download-options {
  position: absolute;
  top: 50px;
  right: 10px;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  z-index: 100;
}

.download-options button {
  display: block;
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: none;
  background-color: #f4f4f4;
  cursor: pointer;
}

.download-options button:hover {
  background-color: #ddd;
}

/* Align Customer List heading and Download button */
.customer-table-header {
  display: flex;
  justify-content: space-between; /* Push items to opposite sides */
  align-items: center;
  margin-bottom: 10px;
  margin-right: 100px;
  /* margin-left: 50px; */
}

.download-note {
  font-size: 14px;
  font-weight: 600;
  color: #31363f;
  margin-top: 10px;
  text-align: center;
  display: block; /* Ensures it's treated as a block-level element */
  width: 100%; /* Makes sure it's centered within its parent */
}


@media (max-width: 768px) {
  .customer-table {
    width: 95%;
    min-width: 90%; /* Prevents it from shrinking too much */
    padding: 15px;
  }

  .customer-table-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .download-button {
    width: 100%;
    text-align: center;
  }

  .close-button {
    top: 5px;
    right: 5px;
  }
}