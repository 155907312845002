/* Modal Title Styling */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .custom-legend .recharts-legend-item {
    font-size: 14px !important;
    margin-top: 5px !important;
    font-weight: bold;
    justify-content: center;
  }
  
  
  /* Button Styling */
  .download-button {
    background-color: #777 !important; /* Gray background */
    color: white !important;
    border: none !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    margin-right: 40px;
  }

  .style-report-title {
    font-size: 16px;
    font-weight: bold;
    color: #333; /* Dark gray */
  }
  
  .download-button:hover {
    background-color: #31363f !important; /* Ensure it turns black */
    color: white !important;
  }

  .ant-tabs-nav {
    background-color: #f5f5f5; /* Light gray background */
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 10px;
    color: #31363f;
  }

  .ant-tabs-tab-active {
    color: #31363f !important; /* Blue color for active tab */
  }
  
  /* Tab Hover Effect */
  .ant-tabs-tab:hover {
    color: #31363f !important; /* Lighter blue on hover */
  }

  .ant-tabs-ink-bar {
    background-color: #31363f !important; /* Blue underline for active tab */
  }
  
  /* Tab Content */
  .ant-tabs-content {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
  }
  
  
  /* Tabs Container */
  .ant-tabs-tab {
    font-weight: bold;
    font-size: 16px;
    color: #333; /* Dark gray */
    transition: color 0.3s ease-in-out;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #31363f !important;
    text-shadow: none !important;
}

/* Ensure the active tab indicator is black */
.ant-tabs .ant-tabs-ink-bar {
    background-color: black !important;
}

/* Optional: Override default hover effect */
.ant-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #31363f !important; /* Blue on hover */
}



  
  /* Center Aligning View Chart Link */
  .view-chart-text {
    cursor: pointer;
    font-size: 16px;
    color: #1890ff;
    transition: color 0.3s ease;
  }
  
  .view-chart-text:hover {
    color: #40a9ff;
    text-decoration: underline;
  }
  
  /* Pie Chart Modal Styling */
  .pie-chart-modal {
    text-align: center;
    color: #888;
  }
  
  /* Responsive Modal Styling */
  @media screen and (max-width: 768px) {
    .modal-header {
      flex-direction: column;
      gap: 10px;
    }
  
    .download-button {
      width: 100%;
      justify-content: center;
    }
  }
  