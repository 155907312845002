.purchased-box {
    background: #ffffff; /* Lighter glass effect */
    width: 350px;
    height: 220px;
    display: flex;
    flex-direction: column;
    border-radius: 12px; /* Rounded corners */
    color: #31363f;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    backdrop-filter: blur(10px); /* Slight blur for more modern effect */
    position: relative;
    overflow: hidden;
}

.purchased-box::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.purchased-box:hover::before {
    opacity: 1;
}

.purchased-box:hover {
    transform: translateY(-8px); /* Slight lift effect */
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.4);
    background: #31363f;
    color: white;
}

.purchased-box:hover .purchased-explore-icon {
    background-color: white;
    color: #31363f;
}

.purchased-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.purchased-label {
    font-size: 18px;
    font-weight: bold;
}

.purchased-feedback-icon {
    font-size: 22px;
    color: #f0c14b; /* Feedback icon color */
    cursor: pointer;
}

.purchased-description {
    font-size: 16px;
    margin-top: 15px;
    text-align: left;
    padding: 0 10px;
    /* font-weight: normal; */
}

.purchased-divider {
    height: 1px;
    background-color: #31363f;
    color: white;
    margin-top: 77px;
}

.purchased-footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.purchased-count {
    font-size: 20px;
    /* font-weight: 700; */
}

.purchased-explore-icon {
    /* border-top: 10; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;   /* Adjust width and height as needed */
    height: 25px;
    border-radius: 50%; /* Creates a circular container */
    background-color: #31363f; /* Customize the background color */
    color: white; /* Icon color */
    font-size: 20px; /* Adjust the size of the icon */
    transform: rotate(300deg); /* Rotates the icon to make it diagonal */
    cursor: pointer; /* Optional: Adds pointer cursor on hover */
  }

.purchased-explore-icon:hover {
    background-color: white;
    color: #31363f;
}

.purchased-box:hover .purchased-divider {
    background-color: white; /* Change to white or any desired color */
}

