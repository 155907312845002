.selectedShoptypes {
  display: flex;
  margin: 0 6% 3%;
  gap: 5%;
}

.Shopping {
  padding: 1.2% 3.5%;
  border-radius: 15px;
  background-color: #31363f;
  font-size: 17px;
  color: white;
  border: 1px solid;
}
.unShopping {
  padding: 1.2% 3.5%;
  border-radius: 15px;
  border: 1px solid;
  background-color: white;
  font-size: 17px;
  color: #31363f;
}

.Wardrobe {
  padding: 1.2% 3.5%;
  border-radius: 15px;
  border: 1px solid;
  background-color: #31363f;
  font-size: 17px;
  color: white;
}
.unWardrobe {
  padding: 1.2% 3.5%;
  border-radius: 15px;
  background-color: white;
  font-size: 17px;
  color: #31363f;
  border: 1px solid;
}
.unShopping:hover,
.unWardrobe:hover {
  background-color: #31363f;
  color: white;
  cursor: pointer;
}

.New-Card-Subheading {
  display: flex;
  justify-content: start;
  padding: 0% 0% 0% 6%;
  font-size: 16px;
}

.nouserassigned {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nouserassigned {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width: 100%;
  font-size: 18px;
  color: #31363f;
  font-weight: bold;
  text-align: center;
}

