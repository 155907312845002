.dashboard-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;  /* Ensures elements are stacked vertically */
}

.header {
  display: flex;
  justify-content: flex-start;  /* Aligns the header to the left */
  align-items: center;
  /* margin-bottom: 20px; */
}

.stats {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;  /* Adds spacing between the header and stats */
}

.services {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;  
}

.purchase-history-section {
  margin-top: 20px;
}

.purchase-history {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;  
}

/* 
.stats .stat-box {
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.stats .stat-box:hover {
  transform: translateY(-8px);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.4);
  background: #31363f; 
  color: white;
}

.stats .stat-box:hover .stat-label,
.stats .stat-box:hover .stat-description,
.stats .stat-box:hover .stat-count {
  color: white; 
}

.stats .stat-box:hover .explore-icon {
  background-color: white; 
  color: #31363f; 
} */