.user-display-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #31363f;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 8px;

  margin: 3% 2%;
}
.hovertodisplaydivm {
  margin: 0;
}
.hovertodisplaydiv {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.hovertodisplaydiv .text {
  margin-top: -1.7rem;
  background: white;
  padding: 4px 16px;

  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 40px 8px 0 0;
  margin-right: -0.15%;
}
.first-div {
  display: flex;
  gap: 4%;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
}

.image-container img {
  width: 68px;
  height: 68px;
  object-fit: cover;
  border-radius: 50%;
  background: white;
  padding: 7%;
}
.image-container {
  display: flex;
  align-items: center;
}

.info-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}
.info-itemdob-body {
  margin: 0;
  font-size: 11px;
  font-weight: 700;
}
.info-item {
  display: flex;
  font-size: 18px;
  gap: 8px;
  font-weight: 600;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.info-itemdob {
  display: flex;
  font-size: 10px;
  gap: 0px;

  align-items: flex-start;
  flex-direction: column;
}

/* .info-item p {
  margin: 0;
  color: #31363f;
  line-height: 1.4;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
} */


.info-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #31363f;
  width: 180px;
}

.info-gender {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #31363f;
}

.info-category {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #31363f;
}






.info-itemdob-heading {
  font-size: 10px;
  margin: 0;
}
.button-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 4px;
}
.button-container-button,
.CurrentServicesButton {
  padding: 8px 21px;
  border: none;
  color: #31363f;
  border: 1px solid;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 30px;
}

.button-container button:hover,
.CurrentServicesButton:hover {
  background-color: #31363f;
  color: white;
}

.second-div {
  margin-top: 16px;
}

.info-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 74%;
  flex-direction: column;
}
