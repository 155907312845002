.userQuestionMainDiv{

    display: flex;
    gap: 2%;
    /* border: 1px solid; */
    border-radius: 8px;
    /* padding: 1% 2%; */
}

/* .answer {
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.6; 
  } */
  
  .answer ul {
    padding-left: 7px;  
    margin-left: 7px;  
    padding-right: 3px;
    margin-right: 3px;
    list-style-position: outside;
  }
  
  

.userQuestionDiv{
    flex: 1;
    border-radius: 8px;
    overflow: hidden;
}
.userQuestionDiv h1{
    font-weight: 500;
    font-size: 20px;
}