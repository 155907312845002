/* Services Section */
.services-section {
    margin-top: 20px;
  }
  
  .services-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* Services Grid */
  .services {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
  
  /* Service Card Styling */
  .service-card {
    background: white;
    color: #31363f;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto; /* Allow card to adjust height based on content */
    min-height: 220px; /* Ensures card doesn't shrink too much */
  }
  
  /* Hover Effect */
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon Container */
  .icon-container {
    font-size: 69px;
    margin-bottom: 10px;
    flex-shrink: 0; 
    margin-top: 10px;
    
  }
  /* .icon-container:hover {
    font-size: 69px;
    margin-bottom: 10px;
    flex-shrink: 0; 
    margin-top: 10px;
    color: #;
  } */
  
  /* Title Styling */
  h4 {
    font-size: 16px;
    margin: 10px 0;
    word-wrap: break-word; /* Allows long words to break and wrap */
    text-align: center;
    hyphens: auto;
    margin-top: auto;
  }
  
  /* Description Styling */
  .service-description {
    font-size: 14px;
    opacity: 0.9;
    text-align: center;
    word-wrap: break-word; /* Prevents overflow for long descriptions */
  }
  
  