.stat-box {
  background: #ffffff; /* Slight transparency for glassmorphism */
  width: 350px;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 12px; /* More modern, rounded corners */
  color: #31363f;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  backdrop-filter: blur(10px); /* Glass effect */
  position: relative;
  overflow: hidden;
}

.stat-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.stat-box:hover::before {
  opacity: 1;
}

.explore-icon-header {
  font-size: 24px; /* Adjust icon size */
  margin-bottom: 10px; /* Space between icon and label */
  cursor: pointer;
}

.stat-box:hover {
  transform: translateY(-8px);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.4);
  background: #31363f;
  color: white;
}

.stat-box:hover .divider {
  background-color: white;
}

.stat-header {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;
}

.stat-label {
  font-size: 18px;
  font-weight: bold;
}

.stat-description {
  font-size: 16px;
  margin-top: 15px;
  text-align: left;
  padding: 0 10px;
}

.divider {
  height: 1px;
  background-color: #31363f;
  /* margin: 20px 0; */
  margin-top: 60px;
}

.stat-footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
}

.stat-count {
  font-size: 18px;
}

.explore-icon {
  /* border-top: 10; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;   /* Adjust width and height as needed */
  height: 25px;
  border-radius: 50%; /* Creates a circular container */
  background-color: #31363f; /* Customize the background color */
  color: white; /* Icon color */
  font-size: 20px; /* Adjust the size of the icon */
  transform: rotate(300deg); /* Rotates the icon to make it diagonal */
  cursor: pointer; /* Optional: Adds pointer cursor on hover */
}

.explore-icon:hover {
  background-color: #4c5c68; /* Optional: Add hover effect */
}


.stat-card-container {
  perspective: 1000px;
}

.stat-card {
  width: 250px;
  height: 150px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.stat-box.flipped {
  transform: rotateY(180deg);
}

.flipped-content {
  transform: rotateY(180deg);
  background: #31363f;
  color: white;
}

.stat-card-front,
.stat-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: white;
}

.stat-card-back {
  transform: rotateY(180deg);
  background: #f8f8f8;
}




