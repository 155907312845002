/* Default styling for notification card */
.notification-card {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* margin-bottom: 16px; */
    padding: 16px;
    display: flex;
  }
  .divider {
    margin: 10px 0;
    border-color: #f0f0f0;
  }
  
  .arrow-icon {
    margin: 0 5px;
    color: #1890ff; /* Use Ant Design's primary color */
    transition: transform 0.3s;
  }
  .arrow-icon:hover {
    transform: translateX(5px); /* Add smooth slide animation */
  }
  
  .notification-card{
    display: flex
    ;
        flex-direction: column;
        justify-content: space-between;
  }
  /* Scoped styles based on parent class */
  .notification-card.some-parent-class .notification-header {
    background-color: #f9f9f9; /* For parent class 'some-parent-class' */
  }
  
  .notification-card.another-parent-class .notification-header {
    background-color: #e6f7ff; /* For parent class 'another-parent-class' */
  }
  
  /* Other common styles */
  .notification-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .notification-info,
  .notification-meta {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .service-id {
    color: #777;
    font-size: 12px;
    font-weight: bold;
  }
  
  .service-id-value {
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
  
  .user-name {
    color: #31363f;
    font-size: 14px;
    font-weight: 500;
  }
  
  .time-ago {
    color: #888;
    font-size: 12px;
  }
  
  .notification-type {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    margin-right: 75px; /* Reduce space on the right */
    display: inline-block;
    align-items: center; /* Aligns items in a single row */
    gap: 5px; /* Controls spacing */
  }
  
  .notification-type.error {
    background-color: #ffcccc;
    color: #d8000c;
  }
  
  .notification-type.info {
    background-color: #31363f;
    color: white;
  }
  
  .divider {
    border-top: 1px solid #ccc;
    margin: 8px 0;
  }
  
  .notification-body {
    margin-bottom: 8px;
  }
  
  .notification-title {
    font-weight: bold;
    font-size: 18px;
    color: #000000d4;
  }
  
  .notification-content {
    margin-bottom: 0;
    color: #555;
    font-size: 14px;
    
  }
  